import logo from './logo.svg';
import './App.css';
import Whatwedo from './whatwedo';
import Home from './home';
import Asset from './assets';
import Investor from './investor';
import Grievance from './greviance';
import Careers from './careers';
import Default from './defaulter';
import Recovery from './recovery_agents';
import Bodvik from './BODvik';
import Contact from './contact';
import Cookie from './cookie';
import Disc from './disclaimer';
import Bodhim from './BODhim';
import Bodmen from './bodmenes';
import Bodnik from './bodnik';
import Bodhar from './bodhar';
import Bodjim from './bodjimmy';
import Bodnee from './bodnee';
import Bodprav from './bodprav';
import Coming from './comingsoon';
import Whis from './whis';
import Fair from './fair';
import Csr from './csr';
import Anti from './anti';
import Prevention from './prevention';
import { Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import InvestmentAnalyst from './investmentAnalyst';
import BackendDeveloper from './backendDeveloper';
import LegalManager from './legalManager';
import Agm_27nov_2021 from './agm_27nov_2021';
import Egm_22july_2021 from './egm_22nd_july_2021';
import Agm_20june_2022 from './agm_20june_2022';
import Agm_7sept_2020 from './agm_7sept_2020';
import Egm_16feb_2021 from './egm_16th_feb_2021';
import DirectorsUpdate from './directorsUpdate';
import Policy from './policies';
import Src from './src';
import Directors from './ourDirectors';
import NRPolicy from './NRPolicy';
import Terms from './termsAndConditions';
import CodeOfConduct from './codeOfConduct';
import JobDescription from './JobDescription';
import Bodanjani from './Bodanjani';
import BodJayant from './BodJayant';
import BodSonal from './BodSonal';
import legalHeir from './legalHeir';
import Bodrakesh from './bodrakesh';

function LayoutWrapper({ Component }) {

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    if (mainContent?.current && !location.hash)
      mainContent.current.scrollIntoView({ behavior: 'smooth' });
  }, [location]);

  return (
    <>
      <Header />
      <div ref={mainContent}>
        <Component />
      </div>
      <a
        href='https://wa.me/+918657050390?text=Yes'
        className='fixed bottom-16 right-9 rounded-full p-3 text-white shadow-xl hover:bg-[#0E9F6E] transition duration-200'
        target='_blank'
		>
			  <img src='/assets/WhatsApp.png' alt='IARC' />
		</a>
      <Footer />
    </>
  )
}

function App() {

  useEffect(() => {
    window.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    }, { passive: false })
  }, []);

  return (
    <>
      <React.Fragment>
        <Routes>
          <Route path="/" exact element={<LayoutWrapper Component={Home} />} />
          <Route path="/what-we-do" element={<LayoutWrapper Component={Whatwedo} />} />
          <Route path="/assets-for-sale" element={<LayoutWrapper Component={Asset} />} />
          <Route path="/updates-and-notices" element={<LayoutWrapper Component={Investor} />} />
          <Route path="/grievance" element={<LayoutWrapper Component={Grievance} />} />
          <Route path="/careers" element={<LayoutWrapper Component={Careers} />} />
          <Route path="/agm-27th-nov-2021" element={<LayoutWrapper Component={Agm_27nov_2021} />} />
          <Route path="/agm-20th-june-2022" element={<LayoutWrapper Component={Agm_20june_2022} />} />
          <Route path="/agm-7th-sept-2020" element={<LayoutWrapper Component={Agm_7sept_2020} />} />
          <Route path="/egm-22nd-july-2021" element={<LayoutWrapper Component={Egm_22july_2021} />} />
          <Route path="/egm-16th-feb-2021" element={<LayoutWrapper Component={Egm_16feb_2021} />} />
          <Route path="/directors-update" element={<LayoutWrapper Component={DirectorsUpdate} />} />
          <Route path="/policies" element={<LayoutWrapper Component={Policy} />} />
          <Route path="/social-responsibility-charter" element={<LayoutWrapper Component={Src} />} />

          <Route path="/wilful-defaulter" element={<LayoutWrapper Component={Default} />} />
          <Route path="/recovery-agents" element={<LayoutWrapper Component={Recovery} />} />
          <Route path="/disclaimer" element={<LayoutWrapper Component={Disc} />} />
          <Route path="/privacy" element={<LayoutWrapper Component={Cookie} />} />
          <Route path="/terms" element={<LayoutWrapper Component={Terms} />} />
          <Route path="/contact-us" element={<LayoutWrapper Component={Contact} />} />

          <Route path="/board-of-directors-vikas-nanda" element={<LayoutWrapper Component={Bodvik} />} />
          <Route path="/board-of-directors-naveen-jain" element={<LayoutWrapper Component={Bodhim} />} />
          <Route path="/board-of-directors-menes-chee" element={<LayoutWrapper Component={Bodmen} />} />
          <Route path="/board-of-directors-harjit-singh-bhatia" element={<LayoutWrapper Component={Bodhar} />} />
          <Route path="/board-of-directors-nikhil-goyal" element={<LayoutWrapper Component={Bodnik} />} />
          <Route path="/board-of-directors-jimmy-tata" element={<LayoutWrapper Component={Bodjim} />} />
          <Route path="/board-of-directors-praveen-kadle" element={<LayoutWrapper Component={Bodprav} />} />
          <Route path="/board-of-directors-neeraj-mohan" element={<LayoutWrapper Component={Bodnee} />} />
          <Route path="/board_of_directors-jayanta_banrjee" element={<LayoutWrapper Component={BodJayant} />} />
          <Route path="/our-board" element={<LayoutWrapper Component={Directors} />} />
          <Route path='/board-of-directors-anjani-kumar' element={<LayoutWrapper Component={Bodanjani}/>} />
          <Route path='/board_of_director-sonal-shrivastava' element={<LayoutWrapper Component={BodSonal}/>} />
          <Route path='/board-of-directors-rakesh-bhatia' element={<LayoutWrapper Component={Bodrakesh}/>}/>

          <Route path="/coming-soon" element={<LayoutWrapper Component={Coming} />} />
          <Route path="/whistle-blower-policy" element={<LayoutWrapper Component={Whis} />} />
          <Route path="/fair-practice-code" element={<LayoutWrapper Component={Fair} />} />
          <Route path="/csr-policy" element={<LayoutWrapper Component={Csr} />} />
          <Route path="/anti-corruption-compliance-policy" element={<LayoutWrapper Component={Anti} />} />
          <Route path="/fraud-prevention-detection" element={<LayoutWrapper Component={Prevention} />} />
          <Route path="/nr-policy" element={<LayoutWrapper Component={NRPolicy} />} />
          <Route path="/code-of-conduct" element={<LayoutWrapper Component={CodeOfConduct} />} />
          <Route path="/legal-heir-demise" element={<LayoutWrapper Component={legalHeir} />} />

          <Route path='/career/:careerId' element={<LayoutWrapper Component={JobDescription} />} />
        </Routes>
      </React.Fragment>
    </>
  );
}

export default App;