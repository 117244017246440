import './App.css'
import BODLayout from './bodLayout';
const Bodrakesh=()=>{
    return (
        <BODLayout
            name=' Mr. Rakesh Bhatia'
            about={
                <>
                Mr. Rakesh Bhatia is a seasoned industry professional with over 25 years of experience with reputed MNCs, Indian companies & start-up ecosystem across India as well as abroad. His experience cuts across several verticals such as Finance & Tax, Audit, Treasury, Consulting, Business, Risk, Operations, Information Technology, Regulatory Reporting, M&A, Investor Relations and Fund raising. 
                <br/><br/>
                As the CFO at Tata Capital, Mr. Bhatia has been instrumental in developing and executing the financial strategy for the Company and aligning the same with the firm’s overall growth objectives. He has played a key role in the Company’s successful navigation through events like the pandemic, rapidly evolving regulatory framework and emergence of new business models.
                <br/><br/>
                Mr. Bhatia’s association with Tata Capital dates back to 2007, when he first joined as Financial Controller as one of the first external hires and then moved on to become the Business head of the Rural Finance vertical. He briefly moved out in 2018 to help incubate start-ups in the financial services space. He rejoined Tata Capital Limited in March 2020 as the Chief Financial Officer. 
                <br/><br/>
                During his tenure as the CFO, Mr. Bhatia has been part of the leadership team which has multiple achievements to its name such as spearheading <b>multiple mergers</b> while ensuring <b>cultural alignment, deploying cutting-edge technologies</b> in processes to <b>improve manpower productivity</b>, and achieving the <b>‘Great Place to Work’</b> certification for 3 years in a row.
                <br/><br/>
                Mr. Bhatia brings with him a wealth of experiences and prior to Tata Capital, he has completed successful stints with other renowned global leaders. Some of his selected past experiences include: 
                i) <b>Financing project finance loans</b> for some of the largest greenfield projects at IDBI, ii) <b>Handling Treasury</b>  for Yamaha India wherein he raised funds from <b>International agencies</b>  and was part of the team to <b>setup their Two-wheeler plant in India</b> , iii) Handling the controllership function for Amex Latin America and <b>implementation of Sarbanes Oxley Act across its global businesses & entities</b>  and iv) <b>Setting up of finance function and spearheading rural finance business</b>  in his prior stint at Tata Capital.
                <br/><br/>
                He is also part of various industry forums and along with it regularly interacts with young minds to get their insights and share back his valuable experiences.
                <br/><br/>
                In terms of academic qualifications, Mr. Bhatia is a certiﬁed Chartered Accountant & Company Secretary and holds a Bachelor’s degree in Commerce from Hansraj College, Delhi University. 
                </>
            }
            img='/assets/Group 1081108rakesh.png'
            designation='Nominee Director of Tata Capital Limited.'
            />
    )
}
export default Bodrakesh;